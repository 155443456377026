@use './config/' as *;
@forward './main-scss/';
@forward './swiper/swiper-bundle';
$table-indicator: (
    "gold",
    "silver",
    "bronze"
);
.waf-about {
    @extend %page-head-blue-bg;
    @extend %vertical-tabs;
    @extend %accordion-common;
    &.waf-component {
        padding-block: 0 var(--space-20);
        @extend %mb-0;
    }
    .head-wrap {
        margin-block: 11.12rem;
        &:has(.banner-logo) {
            @extend %flex-sb-c;
        }
        .subtitle {
            @extend %neutral-0;
            @extend %body-lb;
        }
    }
    .waf-head {
        &::before {
            top: 12rem;
        }
    }
    .waf-body {
        margin-top: var(--space-20-neg);
        max-width: 100%;
        margin-inline: unset;
        background-color: transparent;
        border-radius: unset;
    }
    .tab {
        &-section {
            &:has(.tabs) {
                @extend %flex-column;
                @extend %gap-8;
            }
        }
        &-container {
            @extend %border-radius-m;
            @extend %bg-neutral-0;
            @extend %p-10-4;
        }
    }
    .card {
        &-title {
            @extend %title-40;
            @extend %mb-6;
        }
        &-content-item,
        &-data-item {
            + .card-content-item,
            + .card-data-item {
                @extend %mt-4;
            }
        }
        &-desc {
            @extend %body-l;
            + .card-desc {
                @extend %mt-4;
            }
        }
        &-subtitle {
            font-size: 2.4rem;
        }
        &-description {
            @extend %neutral-70;
            @extend %body-l;
        }
    }
    .history {
        &-wrap {
            @extend %mt-10;
        }
        &-list {
            flex-wrap: wrap;
            @extend %flex-n-c;
            @extend %gap-6;
            @extend %mt-10;
            .thumbnail {
                height: 11rem;
            }
            .logo {
                @extend %h-100;
            }
        }
    }
    .document {
        &-list {
            list-style-type: disc;
            list-style-position: inside;
            @extend %my-2;
            + .document-redirect.redirect-small {
                @extend %mt-6;
                .card-item {
                    min-width: 29.4rem;
                }
            }
        }
        &-item {
            @extend %primary-50;
        }
        &-link {
            text-decoration: underline;
            @extend %primary-50;
            @extend %body-l;
        }
        &-redirect {
            @extend %event-card-small;
            &-list {
                flex-wrap: wrap;
            }
            &-item {
                @extend %w-100;
            }
            &:not(.document-redirect-list) {
                .card-item {
                    width: max-content;
                }
            }
            &.redirect-small {
                .card-item {
                    min-height: 6.4rem;
                }
            }
            .title {
                font-size: 1.4rem;
            }
            .card-item {
                min-height: 8.2rem;
            }
        }
    }
    .congress-banner {
        aspect-ratio: 25/9;
        @extend %my-6;
    }
    .value {
        @extend %body-l;
        &.value-grey {
            color: var(--neutral-40);
        }
        + .value {
            @extend %mt-6;
            &.mt-0 {
                @extend %mt-0;
            }
        }
    }
    .link {
        @extend %primary-50;
        @extend %body-l;
    }
    .mt-0 {
        @extend %mt-0;
    }
    .bold-text {
        font-weight: 700;
    }
    .badge-outline {
        width: max-content;
        min-height: 3rem;
        color: var(--neutral-100);
        border-color: var(--neutral-20);
        @extend %btn-outline-text-icon;
        &:hover {
            color: var(--neutral-100);
            border-color: var(--neutral-20);
        }
    }
    .content {
        &-title,
        &-subtitle {
            @extend %body-m;
        }
        &-title {
            font-weight: 700;
            @extend %flex-column;
            @extend %gap-2;
            img {
                width: 1.6rem;
                height: 1.6rem;
                @extend %circle-radius;
            }
        }
        &-subtitle {
            @extend %mt-1;
        }
    }
    .banner-logo {
        object-position: right;
        object-fit: contain;
        max-height: 3.5rem;
        width: max-content;
        @include position(null, 1.6rem, 16rem);
    }
    .doping-img {
        aspect-ratio: 6/1;
        @extend %my-6;
    }
    .number-bold-list {
        > li {
            &::marker {
                @extend %body-lb;
            }
            + li {
                @extend %mt-6;
            }
        }
    }
    .number-bold-list,
    .number-list {
        list-style: decimal;
        list-style-position: inside;
        li {
            p:first-child {
                display: inline-block;
            }
        }
    }
    ol {
        list-style: decimal;
        @extend %pl-4;
        @extend %my-4;
        > li {
            &::marker {
                @extend %body-l;
            }
        }
        .value {
            &:first-child {
                display: inline;
            }
        }
    }
    .download {
        &-logo,
        &-uniform-logo {
            object-fit: contain;
            object-position: left;
            @extend %my-4;
        }
        &-logo {
            aspect-ratio: 3/1;
            max-height: 10rem;
        }
        &-uniform-logo {
            aspect-ratio: 14/9;
            max-height: 23rem;
        }
    }
    .table {
        @extend %x-auto;
        .image {
            width: 1.6rem;
            aspect-ratio: 1/1;
            flex-shrink: 0;
        }
        .text {
            word-break: break-word;
            @extend %body-s;
            @extend %neutral-70;
        }
        .link {
            .text {
                @extend %text-center;
                @extend %primary-50;
            }
        }
        .country-wrap {
            @extend %flex-sb-c;
            @extend %gap-2;
        }
        .name {
            flex-wrap: wrap;
            @extend %flex-column-c-c;
        }
        .games,
        .gold,
        .silver,
        .bronze {
            flex-basis: 8rem;
        }
        .rank,
        .athlete,
        .nation,
        .olympics,
        .total,
        .noc,
        .location {
            flex-basis: 9rem;
        }
        .year {
            flex-basis: 6.4rem;
        }
        .nation,
        .noc {
            .country-wrap {
                flex-direction: row-reverse;
            }
        }
        .staff {
            flex-basis: 41%;
            &-details {
                flex-basis: 37%;
            }
        }
        .athlete {
            .name {
                align-items: flex-start;
            }
            .text {
                text-align: center;
            }
        }
        &-responsive {
            + .table-responsive {
                @extend %mt-6;
            }
        }
        &-row {
            justify-content: flex-start;
            align-items: stretch;
            &:not(:last-child) {
                border-bottom: 0;
            }
            &:last-child {
                .table-data {
                    border-bottom: 0;
                }
            }
        }
        &-data {
            height: auto;
            flex-grow: 1;
            @extend %p-4-2;
            @include border(1, neutral-20, 10, bottom);
            &:has(.link) {
                .country-wrap {
                    flex-direction: column;
                    @extend %mt-2;
                }
            }
        }
        &-head {
            background-color: var(--neutral-0);
            .text {
                @extend %neutral-100;
                @extend %body-lb;
            }
            .gold,
            .bronze,
            .silver {
                .text {
                    @extend %rounded-radius;
                    @extend %py-3;
                    @extend %w-100;
                }
            }
            @each $indicator in $table-indicator {
                .#{$indicator} {
                    .text {
                        @extend %bg-#{$indicator}-0;
                    }
                }
            }
        }
        &-subtitle {
            + .table {
                @extend %mt-10;
            }
        }
    }
    &.waf-about-contact {
        .card {
            &-data-wrap {
                @extend %mt-8;
            }
            &-content-item {
                @extend %border-radius-m;
                @extend %bg-neutral-0;
                @extend %p-10-4;
                + .card-content-item {
                    @extend %mt-8;
                }
            }
        }
        .article {
            &-list {
                @extend %flex-column;
                @extend %gap-6;
            }
            &-thumbnail {
                .image {
                    // aspect-ratio: 17/10;
                    // max-height: 20rem;
                    @extend %border-radius-m;
                }
            }
            &-content {
                @extend %mt-6;
            }
            &-meta {
                @extend %mt-3;
            }
        }
        .meta {
            &-contact {
                @extend %mt-6;
                span {
                    @extend %d-block;
                }
            }
        }
        .accordion {
            &-item {
                position: relative;
            }
            &-header {
                flex-direction: column;
                align-items: flex-start;
                border-bottom: unset;
                margin-bottom: var(--space-0);
                width: 60%;
                @include position(4.7rem, 0);
                &[aria-expanded=true] ~ .accordion-head {
                    border-bottom: .1rem solid var(--focus-0);
                }
                .btn-text {
                    // font-size: 1.4rem;
                    font-size: 0rem;
                    max-width: 84%;
                }
                &::after {
                    @include position(null, 0, 0rem);
                }
            }
            &-head {
                border-bottom: .1rem solid var(--neutral-20);
                @extend %pb-6;
                @extend %mb-6;
            }
            &-content {
                @include card-count(2, var(--space-4), wrap);
            }
            &-mail {
                width: fit-content;
                display: block;
            }
            &-title {
                font-size: 1.4rem;
                @extend %mb-4;
            }
        }
        .btn-outline {
            font-size: 1.4rem;
            width: fit-content;
            @extend %gap-2;
            @extend %btn-outline-icon;
            &::after {
                font-weight: 400;
                @include icon(mail, 16);
            }
        }
        .content-title {
            .link {
                @extend %body-mb;
            }
        }
    }
    &.waf-about-contact,
    &.waf-about-history {
        .tab {
            &-container {
                border-radius: unset;
                @extend %bg-transparent;
                @extend %p-0;
            }
        }
    }
    &.waf-about-history {
        .card {
            @extend %border-radius-m;
            @extend %bg-neutral-0;
            @extend %p-10-4;
            + .card {
                @extend %mt-8;
            }
            &-title {
                font-size: 3.2rem;
            }
        }
    }
    &.waf-about-environment {
        .table {
            &-responsive {
                @extend %mt-10;
            }
            .text {
                &.document-link {
                    @extend %primary-50;
                }
                &.bold-text {
                    font-weight: 700;
                }
            }
            &-data {
                justify-content: flex-start;
                @extend %text-left;
            }
        }
    }
}
.waf-component {
    &.waf-listing {
        padding-top: 0;
        padding-bottom: var(--space-10);
        margin-bottom: 0;
    }
}
.waf-listing {
    @extend %mx-2-neg;
    @extend %bg-neutral-0;
    @extend %px-4;
    .layout-wrapper {
        max-width: unset;
    }
    &.waf-horizontal-list {
        .article-meta {
            font-size: 1.2rem;
        }
    }
    &.waf-overlay-list {
        .article-list {
            overflow-x: auto;
            @extend %mr-4-neg;
            @include card-count(1.2, var(--space-4));
        }
    }
}
.hall-of-fame {
    .waf-about {
        @extend %member-item;
        .waf-head {
            height: 35rem;
        }
        .tab-container {
            @extend %pb-10;
        }
        &.waf-component {
            padding-block: 0;
        }
        .card {
            &-list {
                @include card-count(1.4, var(--space-4), nowrap);
            }
        }
        .accordion {
            &-body {
                @extend %mb-0;
            }
            &-header {
                &[aria-expanded='true'] {
                    @extend %mb-4;
                    + .accordion-body {
                        @extend %mb-4;
                    }
                }
            }
        }
    }
    .waf-row {
        + .waf-row {
            .waf-body {
                margin-top: 0;
            }
            .tab-container {
                @extend %pt-0;
            }
        }
        + .waf-row:not(:last-child) {
            .tab-container {
                border-radius: 0;
            }
        }
        &:last-of-type {
            .waf-about {
                @extend %mb-10;
                .tab-container {
                    border-radius: 0 0 1.6rem 1.6rem;
                }
            }
        }
        &:first-of-type {
            .tab-container {
                border-radius: 1.6rem 1.6rem 0 0;
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-about {
        .waf-head {
            &::before {
                top: 50%;
            }
        }
        .head-wrap {
            margin-block: 23.3rem 15rem;
            .title {
                font-size: 5.4rem;
            }
        }
        .tab {
            &s {
                border-radius: 2.4rem;
            }
            &-container {
                border-radius: 2.4rem;
                padding: var(--space-12) var(--space-6);
            }
            &-text {
                .btn-text {
                    font-size: 1.6rem;
                }
            }
        }
        .card {
            &-title {
                font-size: 4rem;
            }
            &-subtitle {
                height: max-content;
            }
            &-content-item {
                &.horizontal-item {
                    display: flex;
                    gap: var(--space-6);
                    .card {
                        &-subtitle {
                            margin-bottom: 0;
                            width: 29%;
                        }
                        &-data-wrap {
                            width: 69%;
                        }
                    }
                }
            }
            &-content-item,
            &-data-item {
                + .card-content-item,
                + .card-data-item {
                    margin-top: var(--space-6);
                }
            }
        }
        .congress-banner {
            max-height: 27.5rem;
        }
        .document {
            &-redirect-list {
                @include card-count(3, var(--space-6), wrap);
            }
        }
        .banner-logo {
            position: static;
            max-height: 7.2rem;
        }
        ol {
            margin-block: var(--space-6);
        }
        .table {
            .text {
                font-size: 1.4rem;
            }
            .name {
                flex-direction: row;
                gap: var(--space-1);
            }
            .games {
                flex-basis: 16.9rem;
            }
            .noc {
                flex-basis: 14.9rem;
                justify-content: flex-start;
            }
            .year,
            .location {
                flex-basis: 13.5rem;
            }
            .staff {
                flex-basis: 25%;
                &-details {
                    flex-basis: 75%;
                }
            }
            .athlete {
                .name {
                    flex-direction: column;
                }
            }
            .link {
                .text {
                    text-align: center;
                }
            }
            &-responsive {
                + .table-responsive {
                    margin-top: var(--space-10);
                }
            }
            &-data {
                height: auto;
                flex-grow: 1;
                &:has(.link) {
                    .country-wrap {
                        flex-direction: row;
                        justify-content: center;
                    }
                }
            }
            &-title {
                font-size: 2rem;
            }
        }
        &.waf-about-contact {
            .card {
                &-data-wrap {
                    margin-top: 0;
                }
                &-content-item {
                    border-radius: 2.4rem;
                    padding: var(--space-20) var(--space-10);
                }
                &-subtitle {
                    font-size: 3.2rem;
                }
            }
            .article {
                &-list {
                    margin-top: var(--space-6);
                    @include card-count(3, var(--space-6), wrap);
                }
            }
            .accordion {
                &-list {
                    padding-top: var(--space-8);
                }
                &-item {
                    display: flex;
                    gap: var(--space-6);
                    padding-inline: var(--space-8);
                    + .accordion-item {
                        padding-top: var(--space-8);
                        margin-top: var(--space-8);
                        @include border(1, neutral-20, 10, top);
                    }
                }
                &-head {
                    border-bottom: unset;
                    padding-bottom: unset;
                    margin-bottom: unset;
                    width: calc(30% - var(--space-6));
                }
                &-body {
                    width: 70%;
                    grid-template-rows: 1fr;
                    margin-bottom: 0;
                }
                &-header {
                    display: none;
                    width: 29%;
                    justify-content: flex-start;
                    margin-bottom: 0;
                    padding-block: 0;
                    border-bottom: 0;
                    cursor: default;
                    .btn-text {
                        // font-size: 2rem;
                        display: block;
                    }
                    &::after {
                        content: unset;
                    }
                }
                &-content {
                    @include card-count(3, var(--space-4), wrap);
                }
                &-title {
                    font-size: 2rem;
                }
                &-mail {
                    min-width: 14.8rem;
                }
            }
            .content-title {
                .link {
                    font-weight: 700;
                    font-size: 1.6rem;
                }
            }
            .btn-outline {
                width: 100%;
            }
        }
        &.waf-about-history {
            .card {
                border-radius: 2.4rem;
                padding: var(--space-10);
            }
        }
    }
    .hall-of-fame {
        .waf-about {
            .waf-head {
                height: 45rem;
            }
            .tab-container {
                padding-inline: var(--space-10);
            }
            .card {
                &-list {
                    flex-wrap: wrap;
                    > * {
                        width: calc(100% / 3 - ((2) * var(--space-4) / 3));
                    }
                }
            }
        }
        .waf-row {
            &:last-of-type {
                .waf-about {
                    .tab-container {
                        border-radius: 0 0 2.4rem 2.4rem;
                    }
                }
            }
            &:first-of-type {
                .tab-container {
                    border-radius: 2.4rem 2.4rem 0 0;
                }
            }
        }
    }
    .waf-listing {
        padding-inline: var(--space-10);
        &.waf-horizontal-list {
            .article-meta {
                font-size: 1.4rem;
            }
        }
        &.waf-overlay-list {
            .article-list {
                margin-right: 0;
                gap: var(--space-6);
                > * {
                    width: calc(100% / 3 - (2 * var(--space-6) / 3));
                }
            }
        }
    }
}
@include mq(col-lg) {
    .waf-about {
        .head-wrap {
            // margin-block: 33.4rem 0rem;
            .title,
            .subtitle {
                margin-left: var(--space-10);
            }
        }
        .waf-head {
            // min-height: 60.8rem;
        }
        .waf-body {
            max-width: var(--container-max-width);
            margin-inline: auto;
        }
        .tab {
            &-section {
                &:has(.tabs) {
                    flex-direction: row;
                    gap: var(--space-6);
                    .tabs {
                        width: 33.33%;
                        height: max-content;
                        position: sticky;
                        top: calc(var(--header-height) + var(--header-redirect-height));
                    }
                    .tab-container {
                        width: 66.67%;
                        height: max-content;
                    }
                }
            }
        }
        .content {
            &-title,
            &-subtitle {
                font-size: 1.6rem;
            }
            &-title {
                flex-direction: row;
                align-items: center;
            }
        }
    }
    .waf-listing {
        max-width: var(--container-max-width);
        margin-inline: auto;
        &.waf-horizontal-list {
            .article-list {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        &.waf-overlay-list {
            .article-list {
                overflow-x: unset;
                > * {
                    width: calc(100% / 4 - ((3) * var(--space-4) / 3));
                }
            }
        }
    }
    .hall-of-fame {
        .waf-about {
            .card {
                &-list {
                    > * {
                        width: calc(100% / 4 - ((3) * var(--space-4) / 4));
                    }
                }
            }
        }
    }
}
@include mq(col-desktop) {
    .waf-about {
        .head-wrap {
            .title {
                font-size: 5.6rem;
            }
        }
    }
}